import {graphql, useStaticQuery} from "gatsby";
import React from 'react';
import MainButton from "../MainButton/MainButton";


const SignUpButton = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            webAppUrl
            optiplusWebAppUrl
          }
        }
      }
    `
  );

  const {
    application = 'Optiwai',
    label = "Sign up",
    customRender,
    tight,
    bigger,
    mobile,
    className = ''
  } = props;

  const prepareParams = () => {
    switch (application) {
      case 'OptiwaiPlus':
        return {
          href: `${site.siteMetadata.optiplusWebAppUrl}/auth/register`,
          color: 'green'
        };
      default:
        return {
          href: `${site.siteMetadata.webAppUrl}/sign_up`,
          color: 'mainRed'
        };
    }
  };

  const params = prepareParams();

  if (customRender) {
    return (
      <a
        href={params.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {customRender}
      </a>
    );
  }

  return <MainButton href={params.href} color={params.color} label={label} filled tight={tight} bigger={bigger} mobile={mobile} className={className}/>
};

export default SignUpButton;
