import {graphql, Link, useStaticQuery} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {getLink, optiPlusLoggingEnabled, fastTrackPortalsEnabled, getRoutes} from '../routes';
import {breakpoints} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint";
import css from './header.module.scss';
import {useTranslation} from 'react-i18next';
import HeaderLink from '../HeaderLink/HeaderLink.js';
import OptiwaiPlusLogo from '../../../images/logos/optiwai_plus_full_logo.svg';
import CarsLogo from '../../../images/logos/fastTrack/cars_logo.svg';
// import FastTrackLogo from '../../../images/logos/fastTrack/fast_track_icon.svg';
// import RestorationLogo from '../../../images/logos/fastTrack/restoration_logo.svg';
// import WebPublishingLogo from '../../../images/logos/fastTrack/web_publishing_logo.svg';
// import RealEstateLogo from '../../../images/logos/fastTrack/real_estate_logo.svg';
// import MainButton from "../MainButton/MainButton";
import SignUpButton from "../SignUpButton/SignUpButton";
import OptiwAIPlusLogo from "../../../images/logos/optiwai_plus_full_logo.svg";
import SignInHeaderImg from "../../../images/sign_in_deeplai_www.svg";
import ExternalLinkIcon from "../../../images/icons/external_page.svg";
// import BackgroundImg from "../../../images/home/green_background.svg";
import MenuDropdownArrows from "../MenuDropdownArrows/MenuDropdownArrows";
// import Login from "./Login/Login";
// import SignUp from "./SignUp/SignUp";
// import ForgotPassword from "./ForgotPassword/ForgotPassword";

/*
NOTE:
First version contained modal which are turned off now.
 */

let Header;
Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            webAppUrl
            quickPortals {
              webPublishing
              cars
              realEstate
              restoration
            }
          }
        }
      }
    `
  );

  const { t } = useTranslation();

  /*const [loginOpen, setLoginOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const onForgotPasswordOpenFromLogin = () => {
    setLoginOpen(false);
    setForgotPasswordOpen(true);
  };

  const onLoginOpenFromSignUp = () => {
    setSignUpOpen(false);
    setLoginOpen(true);
  };*/

  const breakpoint = useBreakpoint();
  const mobile = breakpoint.width <= breakpoints.lg;

  const mobileClass = mobile ? css.mobile : '';

  const tight = !mobile;

  const [open, setOpen] = useState(false);

  const openClass = open ? css.open : '';

  const toggleMenu = ()  => {
    if (mobile) {
      setOpen(!open);
    }
  };

  const closeMenu = ()  => {
    setOpen(false);
  };

  const [signInOtherOpen, setSignInOtherOpen] =  useState(false);

  // const quickPortalsUrls = site.siteMetadata.quickPortals;

  const toggleHtmlNodeScroll = () => {
    const htmlNode = document.body.parentElement;
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    if (open) {
      htmlNode.style.paddingRight = `${scrollbarWidth}px`;
      htmlNode.style.overflow = 'hidden';
    } else {
      htmlNode.style.paddingRight = '0px';
      htmlNode.style.overflow = null;
    }
  };

  useEffect(() => {
    toggleHtmlNodeScroll();
  }, [open]);

  useEffect(() => {
    closeMenu();
  }, [mobile]);

  // const renderSolutionsSubmenuItemList = list => {
  //     return list.map(listItemTranslationKey => {
  //         return (
  //             <div className={css.listItem} key={listItemTranslationKey}>
  //                 {listItemTranslationKey} 
  //             </div>
  //         );
  //     });
  // };

  // const renderSolutionsSubmenuItem = props => {
  //   const { page, logo, logoStyle, logoAlt, description, list, background = false } = props;
  //   return (
  //     <Link to={getLink([page])} className={css.solutionsSubmenuLink}>
  //       {background &&
  //       <img
  //           className={css.background}
  //           src={BackgroundImg}
  //           alt={""}
  //       />
  //       }
  //       <div className={css.solutionsSubmenuLogo}>
  //         <img src={logo} alt={logoAlt} style={logoStyle}/>
  //       </div>
  //       <div className={css.solutionsSubmenuText}>
  //         <div className={css.description}>{description}</div>
  //         <div className={css.listWrapper}>{renderSolutionsSubmenuItemList(list)}</div>
  //       </div>
  //       <MainButton
  //           label="Learn more"
  //           isLink={false}
  //           filled
  //           color={'green'}
  //           className={css.solutionsSubmenuButton}
  //       />
  //     </Link>
  //   );
  // };

  // const renderSolutionsSubmenu = (setOpenedCallback) => {
  //   return (
  //       <div className={css.solutionsSubmenu}>
  //         {renderSolutionsSubmenuItem({
  //           page: getRoutes().Solutions,
  //           logo: OptiwAIPlusLogo,
  //           logoStyle: { height: 50 },
  //           logoAlt: "optiwaiplus logo logotype",
  //           description: <>Visual Content Workflow <br/>Processing Automation</>,
  //           list: ["Applying the power of Computer Vision", "Incredible multifunctional quality output"],
  //           background: true
  //         })}
  //         {/* {renderFastTrackDesktopMenu()} */}
  //         <div className={css.solutionsSubmenuOverlay} onClick={() => setOpenedCallback(false)} />
  //       </div>
  //   );
  // };

  const renderMenu = (menu, menuLevel) => {
    return menu.map((item, index) => {
      const {
        key = `menu_${menuLevel || 0}_${index}`,
        page,
        externalLink,
        customItemRender,
        borderedAndGreen,
        isDropdown = false,
        label,
        labelHidden,
        submenu = [],
        renderCustomSubmenuFn,
        setActiveForSubpages
      } = item;
      const submenuLevel = isDropdown ? -1 : menuLevel + 1;
      const submenuToRender = submenu.length ? renderMenu(submenu, submenuLevel) : [];
      return (
        <HeaderLink
          key={key}
          subpage={page}
          externalLink={externalLink}
          customItemRender={customItemRender}
          borderedAndGreen={borderedAndGreen}
          isDropdown={isDropdown}
          text={label}
          textHidden={labelHidden}
          // separator
          mobile={mobile}
          onClick={() => {
            if (!isDropdown) {
              closeMenu();
            }
          }}
          submenu={submenuToRender}
          renderCustomSubmenuFn={renderCustomSubmenuFn}
          menuLevel={menuLevel}
          setActiveForSubpages={setActiveForSubpages}
        />
      );
    });
  };
  
  const mainMenu = [{
    page: [getRoutes().home],
    label: "Home",
  }];

  // if (!mobile) {
  //   mainMenu.push({
  //     key: 'menu_solutions_dropdown',
  //     isDropdown: true,
  //     label: "Solutions",
  //     setActiveForSubpages: ['Solutions'],
  //     renderCustomSubmenuFn: renderSolutionsSubmenu
  //   });
  // }

  // const renderSolutionMobileMainLink = (label, logo, logoStyle = {}) => {
  //   return (
  //       <div className={css.solutionsMobileLink}>
  //         {label}
  //         <img src={logo} alt="" style={logoStyle} />
  //       </div>
  //   );
  // };

  // const fastTrackMenuItems = [{
  //   logo: WebPublishingLogo,
  //   url: quickPortalsUrls.webPublishing,
  //   mobileLabel: "Web Publishing",
  //   keyRender: 'fast_track_web_publishing'
  // }, {
  //   logo: CarsLogo,
  //   url: quickPortalsUrls.cars,
  //   mobileLabel: "Cars",
  //   keyRender: 'fast_track_cars'
  // }, {
  //   logo: RealEstateLogo,
  //   url: quickPortalsUrls.realEstate,
  //   mobileLabel: "Real Estate",
  //   keyRender: 'fast_track_real_estate'
  // }, {
  //   logo: RestorationLogo,
  //   url: quickPortalsUrls.restoration,
  //   mobileLabel: "Aged Photos",
  //   keyRender: 'fast_track_restoration'
  // }];

  // const renderFastTrackDesktopMenuItem = item => {
  //   const { logo, url, keyRender } = item;
  //   return (
  //       <a href={url} target="_blank" className={css.fastTrackMenuItem} key={keyRender}>
  //         <div className={css.logoWrapper}>
  //           <img src={logo} alt=""/>
  //         </div>
  //         <MainButton
  //             label="Learn more"
  //             isLink={false}
  //             filled
  //             color={'green'}
  //             className={css.solutionsSubmenuButton}
  //         />
  //       </a>
  //   );
  // };

  // const renderFastTrackDesktopMenuItems = () => {
  //   return fastTrackMenuItems.map(item => renderFastTrackDesktopMenuItem(item))
  // };

  // const renderFastTrackDesktopMenu = () => {
  //   if (!fastTrackPortalsEnabled) {
  //     return;
  //   }
  //   return (
  //       <div className={css.fastTrack}>
  //         <div className={css.labelWrapper}>
  //           <img src={FastTrackLogo} alt=""/>
  //         </div>
  //         {renderFastTrackDesktopMenuItems()}
  //       </div>
        
  //   );
  // };

  // const addFastTrackItemsToMobileMenu = () => {
  //   if (!fastTrackPortalsEnabled) {
  //     return;
  //   }
  //   fastTrackMenuItems.forEach(item => {
  //     const { logo, url, keyRender, mobileLabel } = item;
  //     const label = (
  //         <div>
  //           {mobileLabel}
  //           <img src={ExternalLinkIcon} alt="" style={{ marginLeft: 8, height: 11 }} />
  //         </div>
  //     );
  //     mainMenu.push({
  //       key: keyRender,
  //       label: renderSolutionMobileMainLink(label, logo, { height: 15 }),
  //       labelHidden: !mobile,
  //       externalLink: url
  //     });
  //   });
  // };

  // const logo = (
  //   <div className={css.signInLogoWrapper}>
  //     <img src={logo} alt={logoAlt} />
  //   </div>
  // );

  // const optiwaiPlusSubmenu = [{
  //   page: [getRoutes().Explore], // Optiwai+ Explore
  //   label: "Explore",
  //   submenu: []
  // }, {
  //   page: [getRoutes().Integrations], // Optiwai+ integrations
  //   label: "Integrations",
  //   submenu: []
  // }, {
  //   page: [getRoutes().Pricing], // Optiwai+ pricing
  //   label: "Pricing",
  //   submenu: []
  // }, {
  //   page: [getRoutes().UserGuide], // Optiwai+ user guide
  //   label: "User Guide",
  //   submenu: []
  // }, {
  //   page: [getRoutes().Campaign], // Optiwai+ campaign
  //   label: "Campaign",
  //   submenu: []
  // }];

  

  // if (!mobile) {
  //   optiwaiPlusSubmenu.unshift({
  //     page: [getRoutes().OptiwaiPlus], // Optiwai+ About
  //     label: <app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak>,
  //     submenu: []
  //   });
  // }

  // mainMenu.push(  {
  //   page: [getRoutes().Solutions], // Optiwai+
  //   label: renderSolutionMobileMainLink(<app-nobreak>OptiwAI<app-plus>+</app-plus></app-nobreak>, OptiwAIPlusLogo),
  //   labelHidden: !mobile,
  //   submenu: optiwaiPlusSubmenu
  // });

  // addFastTrackItemsToMobileMenu();

  // const inspirationsSubmenu = [{
  //   page: [getRoutes().AgedPhoto], // Inspirations Restoration
  //   label: "Aged Photos",
  //   submenu: []
  // }, {
  //   page: [getRoutes().Automotive], // Inspirations cars
  //   label: "Automotive",
  //   submenu: []
  // }, {
  //   page: [getRoutes().DatingSites], // Inspirations Dating Sites
  //   label: "Dating Sites",
  //   submenu: []
  // }, {
  //   page: [getRoutes().OnlineSales], // Inspirations Online sales (E-Commerce)
  //   label: "Online Sales",
  //   submenu: []
  // }, {
  //   page: [getRoutes().PremiumBrands], // Inspirations Premium Brands
  //   label: "Premium Brands",
  //   submenu: []
  // }, {
  //   page: [getRoutes().RealEstate], // Inspirations real_estate
  //   label: "Real Estate",
  //   submenu: []
  // }, {
  //   page: [getRoutes().Sharing], // Inspirations Sharing
  //   label: "Sharing",
  //   submenu: []
  // }, {
  //   page: [getRoutes().WebPublishing], // Inspirations Web publishing
  //   label: "Web Publishing",
  //   submenu: []
  // }];

  // if (!mobile) {
  //   inspirationsSubmenu.unshift({
  //     page: [getRoutes().InspirationAbout], // Inspirations about
  //     label: "About",
  //     submenu: []
  //   });
  // }

  mainMenu.push({
    page: [getRoutes().Solutions], // News
    label: "Solutions",
    submenu: []
  });

  mainMenu.push({
    page: [getRoutes().UseCases], // Inspirations
    label: "Use Cases",
   
  });

  // mainMenu.push({
  //   page: [getRoutes().Inspirations], // Inspirations
  //   label: "Inspirations",
  //   submenu: inspirationsSubmenu
  // });

  mainMenu.push({
    page: [getRoutes().Pricing], 
    label: "Pricing",
    submenu: []
  });


  mainMenu.push({
    page: [getRoutes().news], // News
    label: "News",
    submenu: []
  });

  
  const menuAboutDropdownItems = [];

  menuAboutDropdownItems.push({
    page: [getRoutes().Support], // Support
    label: "Support",
  });

  menuAboutDropdownItems.push({
    page: [getRoutes().Contact], // Contact
    label: "Contact",
  });
  
  menuAboutDropdownItems.push({
    page: [getRoutes().UserGuide], 
    label: "User Guide",
    submenu: []
  });

  menuAboutDropdownItems.push({
    page: [getRoutes().Integrations], 
    label: "Integrations",
    submenu: []
  });

  if (mobile) {
    mainMenu.push(...menuAboutDropdownItems);
  } else {
    mainMenu.push({
      key: 'menu_about_dropdown',
      isDropdown: true,
      label: "About",
      submenu: [...menuAboutDropdownItems],
      setActiveForSubpages: menuAboutDropdownItems.map(item => item.page),
    });
  }
  

  const renderSignInHeader = () => {
    return (
      <div className={css.signInHeader}>
        <img src={SignInHeaderImg} alt=""/>
      </div>
    );
  };

  const renderApplicationSignInLogo = (application, logo, logoAlt) => {
    return null;
    /*return (
        <SignUpButton
          application={application}
          customRender={(
              <div className={css.signInLogoWrapper}>
                <img src={logo} alt={logoAlt} />
              </div>
          )}
        />
    );*/
  };

  const menuSignInDropdownItems = [];

  if (!mobile) {
    menuSignInDropdownItems.push({
      key: 'menu_sign_in_header',
      customItemRender: renderSignInHeader()
    });
  }

  if (optiPlusLoggingEnabled) {
    menuSignInDropdownItems.push({
      key: 'menu_optiwaiplus_sign_in_logo',
      customItemRender: renderApplicationSignInLogo('OptiwaiPlus', OptiwAIPlusLogo, "optiwaiplus logo logotype")
    });
  }

  

  const handleSignInOtherOpenClick = () => {
    setSignInOtherOpen(!signInOtherOpen);
  };

  const renderSignInOtherSectionHeader = () => {
    const headerText = 'Other';
    if (mobile) {
      return (
          <div className={css.signInOthersMobileHeader}>{headerText}</div>
      );
    }
    return (
      <div className={css.signInOthersHeader} onClick={() => handleSignInOtherOpenClick()}>
        <div className={css.signInOthersHeaderContent}>
          {headerText}
          <MenuDropdownArrows opened={signInOtherOpen} />
        </div>
      </div>
    );
  };

  /*menuSignInDropdownItems.push({
    key: 'menu_optiwai_sign_in_logo',
    customItemRender: (
        <div>
          {renderSignInOtherSectionHeader()}
          {(mobile || signInOtherOpen) &&
          <div className={css.signInOtherSectionContent}>
            <SignUpButton
                application={'Optiwai'}
                customRender={(
                    <div className={css.signInOthersListItem}>
                      <div className={css.signInOthersListText}>OptiwAI</div>
                      <img src={ExternalLinkIcon} className={css.signInOthersListIcon} alt=""/>
                    </div>
                )}
            />
          </div>
          }
        </div>
    )
  });*/

  

  /*mainMenu.push({
    key: 'menu_sign_in_dropdown',
    isDropdown: true,
    label: "Sign in",
    submenu: [...menuSignInDropdownItems],
    borderedAndGreen: !mobile
  });*/

  if (mobile) {
    mainMenu.push({
      page: [getRoutes().LegalDocuments], // Legal document
      label: "Legal Documents",
    });
  }

  return (
    <header className={[css.header, css.headerTight, mobileClass, openClass].join(' ')}>

      <div className={css.overlay} onClick={() => toggleMenu()} />

      <div className={css.hamburger} onClick={() => toggleMenu()}>
        <div className={css.line} />
        <div className={css.line} />
        <div className={css.line} />
      </div>

      <div className={css.wrapper}>

        <div className={css.logoWrapper}>
          <Link to={getLink([getRoutes().home])} className={css.logoLink} onClick={() => toggleMenu()}>
            <img src={OptiwaiPlusLogo} className={css.companyLogo} alt={'optiwaiplus logo logotype'}/>
          </Link>
          <div className={css.closeButton} onClick={() => toggleMenu()}>
            <div className={css.closeButtonInner}/>
          </div>
        </div>
        <nav>
          {renderMenu(mainMenu, 0)}
        </nav>
        {/*<div className={css.authButtons}>
          <a href="#" onClick={() => setLoginOpen(true)} className={css.logIn}>Log in</a>
          <a href="#" onClick={() => setSignUpOpen(true)} className={css.signUp}>Sign up</a>
        </div>*/}
      </div>

      {/*<Login
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
        onForgotPasswordOpen={() => onForgotPasswordOpenFromLogin()}
      />
      <SignUp
        open={signUpOpen}
        onClose={() => setSignUpOpen(false)}
        onLoginOpen={() => onLoginOpenFromSignUp()}
      />
      <ForgotPassword
        open={forgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
      />*/}

    </header>
  );
};

export default Header;
