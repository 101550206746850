import React, {useEffect, useState} from "react";
import { useClickOutside } from 'react-click-outside-hook'
import {Link} from "gatsby";
import {getLink,getRoutes} from "../routes";
import MenuDropdown from "../MenuDropdown/MenuDropdown";
import MenuDropdownArrows from "../MenuDropdownArrows/MenuDropdownArrows";

const DropdownLink = props => {
  const {
    submenu = [],
    mobile = false,
    renderCustomSubmenuFn,
    setActiveForSubpages = [],
    renderLinkInner = (renderLinkTextFc) => {
      return renderLinkTextFc();
    }
  } = props;

  const [opened, setOpened] = useState(false);
  const [active, setActive] = useState(false);

  const handleClick = event => {
      event.preventDefault();
      setOpened(!opened);
  };

  const [ref, hasClickedOutside] = useClickOutside();

  if (hasClickedOutside) {
    if (opened) {
      setOpened(false);
    }
  }

  useEffect(() => {
    const currentPage = window.location.pathname;
    if (setActiveForSubpages.find(subpage => currentPage.startsWith(getLink(subpage)))) {
      setActive(true);
    }
  }, []);

  const renderSubmenu = () => {
    if (renderCustomSubmenuFn) {
      return opened ? renderCustomSubmenuFn(setOpened) : null;
    }
    if (submenu.length > 0) {
      return (<MenuDropdown opened={opened} menuLevel={4} mobile={mobile}>{submenu}</MenuDropdown>);
    }
    return null;
  };

  const renderLinkTextWithArrows = (text) => {
    return (
        <>
          {text}
          <MenuDropdownArrows opened={opened} />
        </>
    );
  };

  return (
    <div ref={ref}>
      <Link to={'#'} onClick={ e => handleClick(e) }>
        <span data-active={active}>
          {renderLinkInner(renderLinkTextWithArrows)}
        </span>
      </Link>
      {renderSubmenu()}
    </div>
  );
};

export default DropdownLink;
