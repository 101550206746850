import React, {useEffect} from 'react';
import css from './layout.module.scss';
import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";
import {Link} from 'gatsby';
import {getLink, getRoutes} from '../shared/routes';
import Header from './Header/Header';
import '../../styles/reset.css';
import '../../locales/i18n';
import '../../styles/_global.scss';
import OptiwaiPlusLogo from '../../images/logos/optiwai_plus_full_logo.svg';

const Layout = ({ children }) => {
  // https://github.com/cferdinandi/smooth-scroll
  const addSmoothScrolling = () => {
    require("smooth-scroll")('a[href*="#"]', {
      speed: 300,
      topOnEmptyHash: false,
      offset: function (anchor, toggle) {
        const menuLevel1Active = document.querySelectorAll('[data-menu-active="true"]');
        if (menuLevel1Active.length) {
          return menuLevel1Active[0].offsetHeight;
        }

        return 0;
	  }
      // speedAsDuration: false
    });
  };

  useEffect(() => {
    addSmoothScrolling();
  },[]);

  const renderPasswordPolicyLink = () => {
    const link = getLink([getRoutes().LegalDocuments]);
    return <Link to={link}>Learn more</Link>
  };

  const cookieButtonStyle = {
    border: '2px solid var(--mainGreenColor)',
    borderRadius: '100px',
    background: 'var(--mainGreenColor)',
    fontSize: '.7rem',
    fontWeight: 700,
    letterSpacing: '1.08px',
    color: 'var(--mainWhiteColor)',
    padding: '4px 16px',
  };

  const linkToHome = getLink([getRoutes().home]);


  return (
    <>
      <div className={css.mobileLogoWrapper}>
        <Link to={linkToHome} className={css.mobileLogoLink}>
          <img src={OptiwaiPlusLogo} className={css.CompanyLogo} alt="optiwaiplus logo"/>
        </Link>
      </div>

      <Header />

      <main className="main">{children}</main>

      <div className={css.fixedItemsWrapper}>
        <CookieConsent
          location="bottom"
          buttonText={"OK"}
          buttonStyle={cookieButtonStyle}
          style={{ position: 'relative', width: '100vw', transform: 'translateX(-100%)', padding: '0 100px 0 20px', boxSizing: 'border-box' }}
          contentStyle={{ flex: '1 0 260px' }}
          expires={30}
        >
          <span className={css.cookieText}>
            This website uses cookies to ensure you get the best experience on our website.{" "}
          </span>
          <span className={css.passwordPolicyLink}>{renderPasswordPolicyLink()}</span>
        </CookieConsent>
      </div>

    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
